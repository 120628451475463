.inputWrapper {
	display: flex;
}

.inputForm {
	flex-grow: 1;
	position: relative;
}

.suggested {
	margin-left: 10px;
	width: auto;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	color: var(--muted);
	white-space: pre;
}

.input {
	z-index: 2;
	margin-left: 10px;
	display: inline;
	width: 100%;
	background-color: transparent;
	font-family: var(--font-code);
	font-size: var(--font-size-regular);
	color: var(--light);
	border: none;
	outline: none;
	text-transform: lowercase;
	padding: 0;

	&:focus {
		border: none;
		outline: none;
	}
}

.promptPrefix span:nth-of-type(1) {
	color: var(--blue);
}

.promptPrefix span:nth-of-type(2) {
	color: var(--light-yellow);
}

.validCommand {
	color: var(--green) !important;
}
