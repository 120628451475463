:root {	
	--darkest: #2d2d2d;
	--dark: #353535;
	--background: #1A1B26;
	--medium: #777c85;
	--light: #b3b9c5;
	--muted: #6c6c6d;
	--red: #f2777a;
	--yellow: #ffd479;
	--light-yellow: #a6da95;
	--green: #f5a97f;
	--blue: #a6da95;
	--aqua: #cad3f5;
	--purple: #c6a0f6;
	--font-code: Hack, Consolas, 'Courier New', Courier, monospace;
	--font-normal: 'Fira Code', sans-serif;
	--round-border-radius: 8px;
	--font-size-regular: 13px;
	--font-size-header: 14px;
	--font-size-icon: 14px;
	--scrollbar-color-thumb: #222;

	/* responsive styles */
	--round-border-radius: 8px;
	--font-size-regular: 15px;
	--font-size-header: 50px;
	--font-size-icon: 14px;
}

* {
	box-sizing: border-box;

	&::-moz-selection,
	&::selection {
		background-color: rgba(255, 255, 255, 0.1);
	}
}

strong {
	color: var(--aqua);
	font-family: var(--font-normal);
}

em {
	color: var(--medium);
	font-style: normal;
}

.muted {
	color: var(--muted);
}

.highlighted {
	color: var(--green);
}

body {
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;

	&:hover {
		text-decoration: underline;
	}
}

@media only screen and (max-width: 700px), (max-height: 300px) {
	:root {
		--font-size-regular: 12px;
		--font-size-header: 13px;
		--round-border-radius: 0;
		--font-size-icon: 13px;
	}
}
